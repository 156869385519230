<template>
  <b-card>
    <cabecalho
      title="FINANCEIRO_LANCAMENTOS.PAGE_TITLE"
      description="FINANCEIRO_LANCAMENTOS.PAGE_DESCRIPTION"
      linkRetornar="financeiro"
    />

    <div class="row">
      <div class="col-12 col-md-3 mb-2">
        <b-button
          variant="primary"
          class="w-100"
          @click="irPara('financeiro-contas-pagar')"
        >
          {{ $t('FINANCEIRO_LANCAMENTOS.CONTAS_PAGAR') }}
        </b-button>
      </div>

      <div class="col-12 col-md-3 mb-2">
        <b-button
          variant="primary"
          class="w-100"
          @click="irPara('financeiro-contas-a-receber')"
        >
          {{ $t('FINANCEIRO_LANCAMENTOS.CONTAS_RECEBER') }}
        </b-button>
      </div>

      <div class="col-12 col-md-3 mb-2">
        <b-button
          variant="primary"
          class="w-100"
          @click="irPara('financeiro-lancamentos-historico')"
        >
          {{ $t('FINANCEIRO_LANCAMENTOS.HISTORICO_LANCAMENTOS') }}
        </b-button>
      </div>

      <div class="col-12 col-md-3 mb-2">
        <b-button
          variant="primary"
          class="w-100"
          @click="irPara('financeiro-lancamentos-integracao-contabil')"
        >
          {{ $t('FINANCEIRO_LANCAMENTOS.INTEGRACAO_FINANCEIRA_CONTABIL') }}
        </b-button>
      </div>
    </div>
  </b-card>
</template>
<script>
// Utils & Aux:
import breadcrumb from '@/common/utils/breadcrumb';
import rotas from '@/common/utils/rotas';

// Components:
import { Cabecalho } from '@/components/headers/index';

export default {
  components: { Cabecalho },
  mounted() {
    breadcrumb.definir(this.$store, [
      { titulo: this.$t('FINANCEIRO.FINANCEIRO') },
      { titulo: this.$t('FINANCEIRO_LANCAMENTOS.PAGE_TITLE') },
    ]);
  },
  methods: {
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
